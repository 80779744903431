import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom"
import Home from "./pages/Home";
import NotFound from './components/NotFound';
import Intro from './pages/Intro';
import Ecole from './pages/Ecole';
import Alternance from './pages/Alternance';
import Personnel from './pages/Personnel';
import Conclusion from './pages/Conclusion';
import ScrollToTop from "./components/ScrollToTop";

function App() {
  console.log("https://fr.fiverr.com/mflabz?source=gig_page&gigs=slug%3Adesign-psd-website-or-landing-page%2Cpckg_id%3A1");
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
          <Routes>
            <Route path="/" exact element={<Home />}/>
            <Route path="/intro" exact element={<Intro />}/>
            <Route path="/ecole" exact element={<Ecole />}/>
            <Route path="/alternance" exact element={<Alternance />}/>
            <Route path="/personnel" exact element={<Personnel />}/>
            <Route path="/conclusion" exact element={<Conclusion />}/>
            <Route path="*" component={<NotFound />} />
          </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
