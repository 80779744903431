import React from 'react';
import Footer from '../components/Footer';
import Navigation from './../components/Navigation';

const Conclusion = () => {
    return (
        <div className='conclusion'>
            <header>
                <Navigation />
            </header>
            <section className='container'>
                <h1>Conclusion</h1>
                <p>Finalement j'ai pu mener de nombreux
                projets variés cette année. Que ce
                soit des missions de graphisme,
                de motion designer ou encore de développeur web.
                J'ai apprécié être polyvalent même si je sens
                une nécessité de se spécialiser pour mieux
                réussir. C'est pourquoi après cette année
                et les nombreux périples j'ai décidé de m'orienter
                en motion design. Ainsi, l'année prochaine j'intègre
                le master DA - Motion designer à Ynov Lyon, en alternance
                chez Elkem. Je compte bien mettre en marche mon autoentreprise,
                l'année suivante. Et pourquoi ne pas monter une société
                plus tard ? L'objectif étant de vivre convenablement de ma passion.</p>
                <h2>Rien à voir ici</h2>
                <div className='comment' id='https://fr.fiverr.com/amitpatel54?source=gig_page&gigs=slug%3Acreate-web-applications-using-django-adonisjs-nodejs-reactjs%2Cpckg_id%3A1'></div>
            </section>
            <Footer />
        </div>
    );
};

export default Conclusion;