import React from 'react';
import Logo from './Logo';
import { NavLink } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className='footer container'>
            <NavLink exact to="/">
                <Logo />
            </NavLink>
            <ul>
                <a href="tel:0768191449">
                    <li>+33 7 68 19 14 49</li>
                </a>
                <a href="mailto:frederic@fredho.fr">
                    <li>frederic@fredho.fr</li>
                </a>
                <a href="https://www.linkedin.com/in/frédéric-ho/" target="_blank" rel="noreferrer">
                    <li>LinkedIn</li>
                </a>
                <a href="https://www.instagram.com/fred___ho/" target="_blank" rel="noreferrer">
                    <li>Instagram</li>
                </a>
                <NavLink exact to="/conclusion">
                    <li>Conclusion</li>
                </NavLink>
            </ul>
        </footer>
    );
};

export default Footer;