import React from 'react';
import Navigation from './../components/Navigation';
import { NavLink } from 'react-router-dom';
import Footer from '../components/Footer';
import { HashLink as Link } from 'react-router-hash-link';

const Intro = () => {
    return (
        <div className='intro'>
            <Navigation />
            <header>
                <video src="./videos/gradient-animation.mp4" autoPlay loop muted ></video>
                <div className='container'>
                    <h1>Un pas vers le monde professionnel</h1>
                    <h2>Découvrez l'année d'alternance d'un élève hors du commun !</h2>
                        <Link exact to="/intro/#https://youtu.be/dQw4w9WgXcQ">
                            <div className="button">
                                <p>En savoir plus 👀</p>
                            </div>  
                        </Link>
                </div>
            </header>
            <section className='fred container' id='https://youtu.be/dQw4w9WgXcQ'>
                <h3>Un génie incompris</h3>
                <div className='row'>
                    <div className='img-shadow'>
                        <div className='fred-img'></div>
                    </div>
                    <div>
                        <h4>Qui suis-je ?</h4>
                        <p>Je m'appelle Frédéric HO, j'ai 21 ans et je suis en 3e année à l'ESD spécialité Développement Web.</p>
                        <h4>Les origines</h4>
                        <p>Je viens du PDG, plus particulièrement,
                        une ville à la frontière de la Suisse. J'ai effectué un BAC ES 
                        et après l'avoir obtenu je voulais faire un métier dans l'audiovisuel.
                        Ainsi j'ai postulé dans une école de cinéma qui me plaisait. Au même moment
                        je voulais créer des publicités pour la télévision, c'est ainsi qu'en tapant
                        école de publicité sur google j'ai postulé à l'ESP. C'est au moment des examens
                        et entretiens d'admission que l'on m'a redirigé vers l'ESD, faisant confiance à
                        ma bonne étoile, j'ai accepté.</p>
                    </div>
                </div>
                <p>Après 2 années en cursus initial à temps plein et 2 stages, l'intégration professionnelle s'est accélérée avec une année en alternance. En plus de l'alternance, beaucoup de questions sont apparues concernant mon avenir. Notamment le milieu dans lequel j'allais évoluer professionnellement. En effet, une question revenait souvent en tête : Vais-je me spécialiser en Développement web ou en Motion design ?</p>
            </section>
            <div className='question container'>
                <p>Développement web ou motion design ?</p>
            </div>
            <section className='dark-section container'>
                <h3>Tout savoir sur mon alternance</h3>
                <div>
                    <p>Alternant chez JHDS AFFAIRE, il s'agit d'une société de conseils et gestion
                    pour les entreprises, faisant partie
                    d'une holding JHDS HOLDING. J'ai travaillé pour de nombreuse
                    marque et société de la holding. Tout d'abord, il y a JHDS Technologie,
                    Clim EnR et ITS de la société JHDS TECHNOLOGIE. Les marques font respectivement
                    de l'installation informatique, de l'installation de climatisation et de pompe
                    à chaleur, et de la télécommunication. Ensuite, chez JHDS RESTAURATION j'ai
                    travaillé pour un traiteur lyonnais, Lyon Service Traiteur. Enfin j'ai terminé
                    en travaillant sur un projet de transaction financière, Stramy, pour la société
                    JHDS AFFAIRE.<br/><br/> Comme vous pouvez le deviner, cette année en entreprise a été bien remplie.</p>
                    <img src="./img/holding.png" alt="logo des différentes sociétés" />
                </div>
            </section>
            <section className='happn container'>
                <h3>Que s'est-il passé cette année là ?</h3>
                <div className='buttons'>
                    <NavLink exact to="/ecole">
                        <div className="button-blue">
                            <p>Ecole</p>
                        </div>  
                    </NavLink>
                    <NavLink exact to="/alternance">
                        <div className="button-green">
                            <p>Alternance</p>
                        </div>  
                    </NavLink>
                    <NavLink exact to="/personnel">
                        <div className="button-pink">
                            <p>Personnel</p>
                        </div>  
                    </NavLink>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default Intro;