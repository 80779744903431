import React from 'react';
import Navigation from './../components/Navigation';
import { HashLink as Link } from 'react-router-hash-link';
import Footer from './../components/Footer';

const Personnel = () => {
    return (
        <div className='personnel'>
            <Navigation />
            <header>
                <video src="./videos/gradient-animation.mp4" autoPlay loop muted ></video>
                <div className='container'>
                    <h1>Arrêtons de procrastiner</h1>
                    <h2>Si c'était si facile, tout le monde le ferait</h2>
                    <Link exact to="/personnel/#prout">
                        <div className="button">
                            <p>En savoir plus 👀</p>
                        </div>  
                    </Link>
                </div>
            </header>
            <section className='perso container' id='prout'>
                <h3>Les créations</h3>
                <div className='row'>
                    <div className='img-shadow'>
                        <div className='perso-img'></div>
                    </div>
                    <div>
                        <h4>Inspirer avant de s'inspirer et d'inspirer</h4>
                        <p>J'ai débuté l'année avec le motiontober.
                        Un challenge qui reprend le concept de
                        l'inktober mais pour le motion design.
                        Evenement organisé par qimono, j'ai pu réaliser
                        9 animations au cours du mois d'Octobre. En effet,
                        c'était très intéressant mais épuisant à faire seul
                        au jour le jour. Cependant, je compte bien y reparticiper
                        cette année avec plus de préparation. Ensuite, j'ai pu faire
                        des minis projets pour amuser mes amis comme une chanson ou
                        des illustrations. En plus de ca, j'ai alimenté mes comptes
                        instagram perso et pro de création, comme des affiches ou carrousel.
                        Enfin, je me suis lancé en freelance mais je n'ai pas encore pu me
                        mettre en activité. </p>
                    </div>
                </div>
            </section>
            <section className='crea'>
                <div className="col-3">
                    <div className="content pic1"></div>
                    <div className="content pic2"></div>
                    <div className="content pic3"></div>
                </div>
                <div className="col-2">
                    <div className="content">
                        <video src="./videos/carousel.mp4" autoPlay muted loop playsInline></video>
                    </div>
                    <div className="content">
                        <video src="./videos/flip.mp4" autoPlay muted loop playsInline></video>
                    </div>
                </div>
                <div className="col-1">
                    <div className="content">
                        <video src="./videos/showreel.mp4" autoPlay muted loop playsInline></video>
                    </div>
                </div>
                <div className="col-2">
                    <div className="content">
                        <video src="./videos/curve.mp4" autoPlay muted loop playsInline></video>
                    </div>
                    <div className="content">
                        <video src="./videos/stretch.mp4" autoPlay muted loop playsInline></video>
                    </div>
                </div>
                <div className="col-3">
                    <div className="content pic4"></div>
                    <div className="content">
                        <video src="./videos/drunk.mp4" autoPlay muted loop></video>
                    </div>
                    <div className="content pic5"></div>
                </div>
                <div className="col-4">
                    <div className="content">
                        <video src="./videos/jaliss.mp4" autoPlay muted loop></video>
                    </div>
                    <div className="content pic6"></div>
                    <div className="content pic7"></div>
                    <div className="content pic8"></div>
                </div>
                <div className="col-3">
                    <div className="content pic9"></div>
                    <div className="content pic10"></div>
                    <div className="content">
                        <video src="./videos/simon.mp4" autoPlay muted loop playsinline></video>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default Personnel;