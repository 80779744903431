import React from 'react';
import Navigation from './Navigation';

const NotFound = () => {
    return (
        <div className='NotFound'>
            <Navigation />
            <div className='container'>
                <h1>Erreur 404</h1>
            </div>
        </div>
    );
};

export default NotFound;