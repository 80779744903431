import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Logo from './Logo';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const Navigation = () => {
    const [isNavExpanded, setIsNavExpanded] = useState(false)

    return (
        <nav className='navigation'>
            <div className="container">
                <NavLink exact to="/" className={({ isActive }) => (isActive ? 'inactive' : 'inactive')}>
                    <Logo />
                </NavLink>
                <div className='menu'>
                    <NavLink exact to="/intro" activeClassName="nav-active">
                        INTRO
                    </NavLink>
                    <NavLink exact to="/ecole" activeClassName="nav-active">
                        ECOLE
                    </NavLink>
                    <NavLink exact to="/alternance" activeClassName="nav-active">
                        ALTERNANCE
                    </NavLink>
                    <NavLink exact to="/personnel" activeClassName="nav-active">
                        PERSONNEL
                    </NavLink>
                </div>
                <button onClick={() => {
                    setIsNavExpanded(!isNavExpanded);
                    }}>
                    <MenuIcon className={isNavExpanded ? "burger-none" : "burger"} />
                    <CloseIcon className={isNavExpanded ? "close" : "close-none"} />  
                </button>
            </div>
            <div className={isNavExpanded ? "menu-mobile-expanded" : "menu-mobile"} >
                <NavLink exact to="/intro" activeClassName="nav-active">
                    INTRO
                </NavLink>
                <NavLink exact to="/ecole" activeClassName="nav-active">
                    ECOLE
                </NavLink>
                <NavLink exact to="/alternance" activeClassName="nav-active">
                    ALTERNANCE
                </NavLink>
                <NavLink exact to="/personnel" activeClassName="nav-active">
                    PERSONNEL
                </NavLink>
            </div>
        </nav>
    );
};

export default Navigation;