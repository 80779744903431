import React from 'react';
import Navigation from './../components/Navigation';
import { HashLink as Link } from 'react-router-hash-link';
import Footer from '../components/Footer';

const Alternance = () => {
    return (
        <div className='alternance'>
            <Navigation />
            <header>
                <video src="./videos/gradient-animation.mp4" autoPlay loop muted ></video>
                <div className='container'>
                    <h1>¿Que passa en alternance?</h1>
                    <h2>还可以</h2>
                    <Link exact to="/alternance/#https://www.google.com/search?q=template+react&rlz=1C1GCEA_enCH992FR992&oq=template+react&aqs=chrome.0.0i512l7j69i60.10186j0j4&sourceid=chrome&ie=UTF-8">
                        <div className="button">
                            <p>En savoir plus 👀</p>
                        </div>  
                    </Link>
                </div>
            </header>
            <section className='slave container' id='https://www.google.com/search?q=template+react&rlz=1C1GCEA_enCH992FR992&oq=template+react&aqs=chrome.0.0i512l7j69i60.10186j0j4&sourceid=chrome&ie=UTF-8'>
                <h3>Des missions variées</h3>
                <div className='row'>
                    <div className='img-shadow'>
                        <div className='slave-img'></div>
                    </div>
                    <div>
                        <h4>Multi-casquette</h4>
                        <p>Travaillant dans une petit
                        entreprise à faible effectif j'ai
                        du être multi casquette. J'ai pu
                        travailler sur des supports print
                        (carte de visite, flyers et kakemono),
                        des illustrations, des vidéos, le développement
                        et l'entretien des sites ou encore sur
                        des campagnes google ads. Comme expliqué
                        précédemment, j'ai travaillé sur plusieurs
                        marques de mon employeur. J'ai ainsi pu
                        faire les sites web de A à Z (design, intégration)
                        sous WordPress de JHDS Technologie (informatique),
                        Lyon service traiteur (restauration),
                        Clim EnR (climatisation) et ITS
                        (operateur integrateur telecom).
                        Sans oublier le dernier projet sous react
                        pas encore mis en prod, <a href='https://github.com/FFRRED/STRAMY'>Stramy</a> (transfere financier).
                        J'ai principalement travaillé pour Clim EnR et ITS.</p>
                     </div>
                </div>
            </section>
            <section className='dark-section container'>
                <div>
                    <h3>Les missions</h3>
                    <h4>Chez Clim EnR</h4>
                    <div className='content'>
                        <p>Chez clim enr, j'ai pu réaliser des missions
                        de graphisme comme des cartes de visite,
                        des flyers, des bannières mails, des fiches
                        de poste ainsi que l'entretien du site.</p>
                        <img src="./img/climenr.gif" alt="" />
                    </div>
                </div>
                <div className='week-project'>
                    <h4>Chez ITS</h4>
                    <div className='content-reverse'>
                        <div>
                            <p>Chez ITS, j'ai également effectué des
                            missions de graphisme comme des cartes
                            de visite, des flyers, un kakemono, des illustrations
                            et des icônes. J'ai également pu faire des missions web
                            en général comme l'entretien du site, la création de
                            la page LinkedIn ou encore des campagnes google ads.</p>
                            <img src="./img/its.gif" alt="" />
                        </div>
                        <video src="./videos/its.mp4" controls poster='./img/cover-its.jpg'></video>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default Alternance;