import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import Footer from '../components/Footer';
import Navigation from './../components/Navigation';

const Ecole = () => {
    return (
        <div className='ecole'>
            <Navigation />
            <header>
                <video src="./videos/gradient-animation.mp4" autoPlay loop muted ></video>
                <div className='container'>
                    <h1>Les projets à l'ESD</h1>
                    <h2>La théorie avant la pratique</h2>
                    <Link exact to="/ecole/#https://youtu.be/DbYvWaF_L4o">
                        <div className="button">
                            <p>En savoir plus 👀</p>
                        </div>  
                    </Link>
                </div>
            </header>
            <section className='project container' id='https://youtu.be/DbYvWaF_L4o'>
                <h3>Des projets concrets pour une meilleure immersion</h3>
                <div className='row'>
                    <div className='img-shadow'>
                        <div className='projet-img'></div>
                    </div>
                    <div>
                        <h4>Pour commencer</h4>
                        <p>J'ai pu réaliser de nombreux projets
                        à l'école que ce soit en développement
                        web, en création et même en stratégie.
                        Le premier projet en équipe fut comme
                        chaque année le digital event. C'est
                        un évènement organisé par l'école rassemblant
                        l'ESP et l'ESD dans la préparation d'atelier
                        pour la soirée des diplômées et des professionnels.
                        Cette année, j'ai pu travailler en tant que motion
                        designer pour la production de teaser et aftermovie. </p>
                    </div>
                </div>
            </section>
            <section className='dark-section container'>
                <div>
                    <h3>Des semaines de projets</h3>
                    <h4>Moulinex</h4>
                    <div className='content'>
                        <p>Par la suite, Nous avons travaillé pendant
                        une semaine sur un projet annonceur. Le client
                        était le groupe SEB avec les produits Moulinex.
                        Ici, j'ai été motion designer et j'ai pu réaliser
                        une vidéo illustrant la stratégie de communication.</p>
                        <video src="./videos/moulinex.mp4" controls poster='./img/cover-video-moulinex.png'></video>
                    </div>
                </div>
                <div className='week-project'>
                    <h4>Beaujeu</h4>
                    <div className='content-reverse'>
                        <p>Ensuite, il y a eu une semaine de projet
                        de spécialisation. Etant en spécialisation
                        développement web, j'ai travaillé sur une
                        intégration d'un quizz avec des éléments
                        en 3D. En tant qu'assistant de création
                        j'ai pu réaliser l'objet 3D et une animation de chargement.</p>
                        <video src="./videos/rock.mp4" muted autoPlay loop poster='./img/rock.jpg'></video>
                    </div>
                </div>
                <div>
                    <h4>Décathlon</h4>
                    <div className='content'>
                        <p>Enfin, nous avons terminé l'année
                        avec une dernière semaine de projet.
                        Cette fois-ci, c'était pour l'application
                        Décathlon Outdoor. J'ai pu participé à
                        l'élaboration du concept de communication
                        et des différents visuels pour notre stratégie.</p>
                        <img src="./img/decathlon-poster.png" alt="" />
                    </div>
                </div>
            </section>
            <section className='small-project container'>
                <h3>Petit, mais costaud</h3>
                <div className='row'>
                    <div className='column first'>
                        <p>Nous avons également pu travailler sur des projets
                        plus petits tout au long de l'année comme sur la
                        communication d'un futur métier (Dream), sur des
                        déclinaisons de visuels d'Apple, la conception
                        de logo ou encore un projet vidéo.</p>
                        <div className='row'>
                            <div className='content1'></div>
                            <div className='content2'></div>
                        </div>
                        <div className='content3'>
                            <video src="./videos/apple.mp4" controls poster='./img/cover-apple.jpg'></video>
                        </div>
                    </div>
                    <div className='column second'>
                        <div className='content4'></div>
                        <div className='content5'>
                            <video src="./videos/digital-event.mp4" controls poster='./img/cover-digital.jpg'></video>
                        </div>
                    </div>
                </div>
            </section>
            <section className='dark-section container'>
                <div>
                    <h3>Projet fils rouge</h3>
                    <h4>ECOMSAR</h4>
                    <div className='content'>
                        <p>Tout au long de l'année scolaire nous
                            devions également tenir un projet
                            fil rouge. J'ai donc pu travailler
                            avec ECOMSAR pour les marques SweatPlaid
                            en hiver et MaboutiqueZen. J'ai principalemen
                            réalisé des vidéos publicitaires ou des visuels
                            pour les réseaux sociaux (Instagram & TikTok).</p>
                        <video src="./videos/sweatplaid.mp4" controls></video>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default Ecole;