import React from 'react';
import Navigation from './../components/Navigation';
import { HashLink as Link } from 'react-router-hash-link';
import Footer from './../components/Footer';

const Home = () => {
    const entreprise = "{entreprise}"
    return (
        <div className='home'>
            <Navigation />
            <header>
                <video src="./videos/gradient-animation.mp4" autoPlay loop muted ></video>
                <div className='container'>
                  <h1>Hey ! Je suis Frédéric HO chef de projet digital Chez <span>{entreprise}</span></h1>
                  <h2>Découvrez comment s'est déroulé mon année 2021-2022 !</h2>
                </div>
            </header>
            <section className='container'>
                <video src="./videos/film-grain.mp4" autoPlay loop muted ></video>
                <Link exact to="/intro" className='gradient-one'>
                    <div className='intro card'>
                        <h3>INTRO</h3>
                    </div>
                </Link>
                <Link exact to="/ecole" className='gradient-two'>
                    <div className='ecole card'>
                        <h3>ECOLE</h3>
                    </div>
                </Link>
                <Link exact to="/alternance" className='gradient-two'>
                    <div className='alternance card'>
                        <h3>ALTERNANCE</h3>
                    </div>
                </Link>
                <Link exact to="/personnel" className='gradient-one'>
                    <div className='personnel card'>
                        <h3>PERSONNEL</h3>
                    </div>
                </Link>
            </section>
            <Footer />
        </div>
    );
};

export default Home;